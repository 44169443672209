<template>
    <div class="center myCenter">
        <!-- 头部 -->
        <header>
            <div class="beijin"></div>
            <div class="flex aic users pixed" style="width:50%;">
                <img :src="require('../../../assets/img/personal/userHead.png')" class="imege" />
                <span class="f16 fff ml-20" v-if="userInfo.userNameNeedTranslate == '1'"><TranslationOpenDataText type='userName' :openid='userInfo.name'></TranslationOpenDataText></span>
                <span class="f16 fff ml-20" v-else>{{userInfo.name}} </span>
            </div>
        </header>

        <!-- 主体 -->
        <main class="mt-10">
            <div class="bg-fff">
                <!-- <div v-if="userInfo.companyId == '7'"> -->

                <van-cell title="当前版本" :title-style="'flex: 0.5;'" :value="version"></van-cell>

                <div style="height: 20px;background: #f4f4f4"></div>
                <!-- </div> -->
                <van-cell title="账号" v-if="userInfo.userNameNeedTranslate != '1'" :title-style="'flex: 0.5;'" :value="userInfo.phone"></van-cell>
                <van-cell title="角色" :title-style="'flex: 0.5;'" :value="userInfo.roleName"></van-cell>
                <van-cell title="工号" v-if="userInfo.jobNumber" :title-style="'flex: 0.5;'" :value="userInfo.jobNumber"></van-cell>

                <div style="height: 20px;background: #f4f4f4"></div>
                
                <van-cell title="公司" :title-style="'flex: 0.5;'" :value="userInfo.companyName"></van-cell>
                <van-cell title="有效日期" :title-style="'flex: 0.5;'" :value="expirationDate"></van-cell> 

                <div style="height: 20px;background: #f4f4f4"></div>
                <!-- <div v-if="userInfo.companyId == '7'"> -->
                <van-cell title="使用说明" :title-style="'flex: 1;'" is-link @click="instructions()"></van-cell>
                <van-cell title="在线客服" :title-style="'flex: 1;'" is-link @click="tokefu()"></van-cell>
                <van-cell title="添加员工" :title-style="'flex: 1;'" is-link @click="addEmployee()" v-if="wxManager"></van-cell>
                <!-- </div> -->
                <!-- <van-cell title="修改密码" isLink to="/my/set"></van-cell> -->
            </div>
            <van-cell :title="'绑定'+(isCorpWX?'企业':'')+'微信'" v-if="userInfo.userNameNeedTranslate != '1' && (isCorpWX || isWX)" @click="bindWeiXin" style="margin-top:10px;" :title-style="'flex: 2.5;'" label="绑定微信后可接收工时填报提醒">
                <template>
                    <span v-if="(isCorpWX && userInfo.corpwxUserid == null) || (isWX && userInfo.wxOpenid == null)" style="color:#ff0000;">未绑定</span>
                    <span v-if="(isCorpWX && userInfo.corpwxUserid != null) || (isWX && userInfo.wxOpenid != null)" style="color:#7CCD7C;">已绑定</span>
                </template>
            </van-cell>
            <van-button class="logout" @click="logout" block round type="danger" v-if="!isCorpWX">退出登录</van-button>
            <!-- <van-button class="logout" @click="logout" block round type="danger" >退出登录</van-button> -->
        </main>

        <Footer page="my" />
    </div>
</template>

<script>
    import Footer from "@/components/Footer";
    import { mapGetters } from "vuex";
    export default {
        components: {
            Footer
        },

        computed: {
            ...mapGetters(["userId", "isLogin"])
        },

        data() {
            return {
                userInfo: JSON.parse(localStorage.userInfo),
                isCorpWX:false,
                isWX:false,
                expirationDate: '', // 有效日期
                version: '', // 版本
                show: false,
                
                wxManager: false,
            }
        },

        methods: {
            logout() {
                this.$store.commit("updateLogin", false);
                localStorage.removeItem("userInfo");
                this.$router.push("/login");
            },
            bindWeiXin(){
                //企业微信
                if (this.isCorpWX && this.userInfo.corpwxUserid != null) {
                    return;
                }
                //微信
                else if (this.isWX && this.userInfo.wxOpenid != null) {
                    return;
                }
                var appId = "wx749c84daac654e1e";//工时管家公众号
                var url = "http://mobworktime.ttkuaiban.com/api/wechat/bindWeiXin2?userId="+this.userInfo.id;//工时管家公众号授权回调页面
                if (this.isCorpWX) {
                    appId = "ww4e237fd6abb635af"; //企业微信第三方的SUIT ID
                    url = "http://worktime.ttkuaiban.com/api/wxcorp/bindCorpWeiXin?userId="+this.userInfo.id;//授权回调页面
                } 

                var weixinUrl="https://open.weixin.qq.com/connect/oauth2/authorize?appid="+appId+"&redirect_uri="+encodeURI(url)+"&response_type=code&scope=snsapi_base&state=0#wechat_redirect";
                window.location.href = weixinUrl;
            },
            // 使用说明
            instructions() {
                let url = 'http://celiang.oss-cn-hangzhou.aliyuncs.com/measurement/2022-01/18/75it6phpocqYFV1642488558220118.pdf'
                let name = '使用说明书'
                // 将要传过去的值
                this.previewPDF(url, name)
            },
            // 预览pdf
            previewPDF(url, name) {
                this.$router.push({
                    path:  '/pdf',
                    query: {
                        url: '',
                        name: name
                    }
                })
            },
            // 添加员工
            addEmployee() {
                wx.invoke('openAppManage', {}, function(res){
                    console.log(res, '你在看看')
                    if(res.err_msg == "openAppManage:ok") {
                        // 调用成功              
                    }
                    if(res.err_msg == "openAppManage:fail:no permission") {
                        // 调用人身份不符合                 
                        this.$message({message: '调用人身份不符合',type: "error"});
                    }
                    if(res.err_msg == "openAppManage:fail:unknown app") {
                        // 应用信息获取失败
                        this.$message({message: '应用信息获取失败',type: "error"});
                    }
                    if(res.err_msg == "openAppManage:fail:unsupported app type") {
                        // 应用类型不符合要求
                        this.$message({message: '应用类型不符合要求',type: "error"});
                    }
                    if(res.err_msg == "openAppManage:fail") {
                        // 其它错误                  
                        this.$message({message: '其它错误',type: "error"});
                    }      
                })
            },
            // 跳转客服
            tokefu(){
                wx.invoke('openThirdAppServiceChat', {
                    }, function(res) {
                        console.log('invoke',res);
                        if (res.err_msg == "openThirdAppServiceChat:ok" || res.err_msg == "openThirdAppServiceChat:cancel") {
                        }else{
                            this.$toast.fail('请联系管理员添加客服');
                        }
                        
                    }
                );
            },
            // 判断是否有添加员工的权限
            getWxManager() {
                this.$axios.post("/user/isManager", {})
                .then(res => {
                    if(res.code == "ok") {
                        let bur = res.data + ''
                        this.wxManager = bur == 'true'
                    } else {
                        this.$toast.clear();
                        this.$toast.fail(res.msg);
                    }
                }).catch(err=> {this.$toast.clear();});
            }
        },
        create() {
        },
        mounted() {
            var ua = navigator.userAgent.toLowerCase();
            if (ua.indexOf("wxwork") > 0) {
                this.isCorpWX = true;
            } else if (ua.indexOf("micromessenger") > 0) {
                this.isWX = true;
            }
            
            // 拼接有效日期 和 版本
            if(localStorage.userInfo) {
                // 日期
                let userss = JSON.parse(localStorage.userInfo)
                this.expirationDate = userss.company.expirationDate[0] + '-' + 
                (userss.company.expirationDate[1] >= 10 ? userss.company.expirationDate[1] : ('0' + userss.company.expirationDate[1]))
                 + '-' + (userss.company.expirationDate[2] >= 10 ? userss.company.expirationDate[2] : ('0' + userss.company.expirationDate[2]))

                // 版本
                userss.company.packageWorktime == 1 ? this.version = '基础版' : ''
                userss.company.packageProject == 1 ? this.version = '专业版' : ''
                userss.company.packageEngineering == 1 ? this.version = '建筑工程版' : ''
                userss.company.packageOa == 1 ? this.version = '旗舰版' : ''

                console.log(this.version)
            }

            this.getWxManager()
        }
    };
</script>

<style lang="less" scoped>
    .myCenter {
        padding-bottom: 50px;
    }
    /* 本页公共样式 */
    .gray {
        color: #797d82;
    }

    .bott0 {
        bottom: 0;
    }

    .orange {
        color: #ff5f16
    }

    .yellow {
        color: #ffb232
    }

    .bd-gray {
        border-bottom: 1px solid #f5f5f5;
    }

    // 头部图片
    .beijin {
        background: url(../../../assets/img/personal/head_back.jpg) repeat-y center center;
        height: 200px;
        background-size: cover;
        margin-top: -44px;
    }

    // 用户名和头像
    .users {
        top: 55px;
        left: 22px;
        position: absolute;

        // 头像
        .imege {
            height: 65px;
            border-radius: 50%;
        }

        .fff {
            font-size: 22px;
        }
    }

    // 导航
    .nav {
        height: 75px;

        .iconfont {
            font-weight: bold;
        }

        .cit {
            height: 26px;
        }
    }

    .logout {
        width: 80%;
        margin: 50px auto;
    }

    // 主体
    main {
        .list {
            height: 50px;

            .image {
                height: 20px;
            }
        }

        .foun {
            font-size: 12px;
        }

    }

    /* 底部 */
    .footer {
        height: 50px;
    }

    // 扫码 
    .scanCode {
        padding: 10px;
    }
    .scanCode_img img {
        width: 100%;
    }
</style>